<template>
  <div>
    <!-- Header -->
    <div class="image-header element-container no-padding">
      <b-carousel
        class="carousel"
        :interval="8000"
        controls
        indicators
        background="#ababab"
      >
        <b-carousel-slide
          caption-html="<div class='display-4 d-none d-xl-block d-lg-block'>Ganzheitliche und kompetente Apothekenberatung für Ihren Erfolg</div>"
        >
          <template #img>
            <b-img
              class="carousel-img"
              src="/assets/visuals/teamwork-carousel.png"
            ></b-img>
          </template>
        </b-carousel-slide>

        <b-carousel-slide
          caption-html="<div class='display-4 d-none d-xl-block d-lg-block'>Apothekenberatung mit dem bestenmöglichen Netzwerk</div>"
        >
          <template #img>
            <b-img
              class="carousel-img"
              src="/assets/visuals/kunde-carousel.png"
            ></b-img>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>

    <div class="my-lg-4 py-lg-1"></div>

    <!-- Beschreibung -->
    <div
      class="
        d-flex
        flex-wrap
        justify-content-center
        align-items-center
        mt-lg-4
        py-3
        text-left
      "
    >
      <div id="intro-img" class="p-4 mr-3 order-1 order-lg-0">
        <b-img
          src="/assets/contact/contact-all-min.png"
          fluid
          thumbnail
          rounded
          alt="Kontakt Personen"
        ></b-img>
      </div>
      <div id="intro-description" class="p-4 order-0 order-lg-1">
        <h1>Apotheken&shy;beratung Wigand-Heppelmann</h1>
        <div>
          <p>
            Wir beraten Sie bei der Gründung, Weiterentwicklung und Veräußerung
            Ihrer Apotheke. Profitieren Sie von unserem Wissensvorsprung und
            unseren Partnerschaften. Zudem bieten wir Ihnen die Möglichkeit, an
            unseren <a href="/erfa">Erfa Tagungen</a> teilzunehmen und sich hier
            im kollegialen Austausch mit neuen Ideen aufzuladen.
          </p>
          <p>
            Durch unsere jahrzehntelange Selbständigkeit und Erfahrung in
            Apotheken und Großhandel verfügen wir über ein großes Netzwerk, das
            wir zu Ihrem Vorteil einsetzen.
          </p>
          <p>
            Die Zusammenarbeit mit unseren Auftraggebern sehen wir nicht als
            klassisches Dienstleistungsverhältnis, sondern als
            partnerschaftliches Miteinander, das Sie und Ihre Apotheke nach
            vorne bringt.
          </p>
          <p>
            Unser Ziel ist es, Ihre Apotheke langfristig und nachhaltig
            erfolgreicher zu machen. Wir wollen gemeinsam den Wandel der Zeit
            erkennen, damit sich Ihre Apotheke zeitgemäß weiterentwickeln kann.
          </p>
        </div>
        <div class="text-center text-lg-left">
          <b-button
            variant="primary"
            class="button px-xl-4 px-sm-1 text-center"
            href="/ueber"
          >
            Mehr über uns erfahren
          </b-button>
        </div>
      </div>
    </div>

    <div class="my-lg-4 py-lg-1"></div>

    <!-- Leistungen -->
    <div class="services my-4 element-container">
      <h2>Unser Beratungs-Portfolio</h2>
      <div class="service-description">
        Unsere Beratung für den Erfolg Ihrer Apotheke richtet sich immer nach
        den Erfordernissen, die Sie in Ihrem Auftrag formulieren.
        <br />
        Wir beraten Sie auf Wunsch in der gesamten Bandbreite des
        Apothekenbetriebes oder in klar definierten Aufgabenfeldern, die wir
        Ihnen wie folgt anbieten.
      </div>
      <div class="my-4"></div>
      <b-card-group deck>
        <b-card
          v-for="service in getChildsOfSite('beratung')"
          :key="service.name"
          :title="service.label.replace('[shy]', '')"
          :img-src="service.img_min"
          :img-alt="service.label.replace('[shy]', '')"
          img-top
        >
          <b-card-text>
            <b-list-group class="service-sublist">
              <b-list-group-item
                v-for="subService in getChildsOfSite(service.name)"
                :key="subService.name"
                :href="getSitePath(subService.name)"
              >
                <b-icon :icon="subService.icon" class="mr-3"></b-icon>
                <span>{{ subService.label.replace("[shy]", "&shy;") }}</span>
              </b-list-group-item>
            </b-list-group>
          </b-card-text>
        </b-card>
      </b-card-group>
    </div>

    <div class="my-lg-4 py-lg-1"></div>

    <!-- Coaching -->
    <div class="my-5 element-container">
      <h2>Unser Coaching-Angebot</h2>
      <div class="service-description">
        Mit unserem Coaching-Angebot sollen konkrete Ziele in Ihrem
        Apothekenbetrieb verfolgt werden.
        <br />
        Wir begleiten Sie bei Ihren Herausforderungen, geben Ihnen Feedback und
        unterstützen Lernprozesse, zum Beispiel bei der Erarbeitung eines
        Schulungs- und Maßnahmenplanes für Ihre Mitarbeiter.
      </div>
      <div class="my-4"></div>
      <b-card-group deck>
        <b-card
          v-for="service in getChildsOfSite('coaching')"
          :key="service.name"
          :title="service.label.replace('[shy]', '&shy;')"
        >
          <div class="d-flex flex-column" style="height: 80%">
            <div class="p-2">{{ service.description }}</div>
            <div class="p-2 mt-auto">
              <b-button
                variant="primary"
                class="button px-xl-4 px-sm-1"
                :href="getSitePath(service.name)"
              >
                Mehr erfahren
              </b-button>
            </div>
          </div>
        </b-card>
      </b-card-group>
    </div>

    <div class="my-lg-4 py-lg-1"></div>

    <!-- ERFA -->
    <div>
      <h2>ERFA-Tagungen</h2>
      <div class="my-4"></div>
      <b-card align="left" class="overflow-hidden reader-area-xl">
        <b-row no-gutters>
          <b-col md="6">
            <b-card-img-lazy
              src="/assets/erfa/erfa-praesentation-sm.jpg"
              alt="Präsenatation auf der ERFA Tagung"
            >
            </b-card-img-lazy>
          </b-col>
          <b-col md="6">
            <b-card-body title="Tagungen zum Erfahrungsaustausch">
              <b-card-text>
                Wir veranstalten zweimal im Jahr ERFA-Tagungen mit
                unterschiedlichen Themenbereichen.
                <br />
                Die Abkürzung "ERFA" steht für den Erfahrungsaustausch, welchen
                wir bei diesen Tagungen anstreben.
              </b-card-text>
              <b-button
                variant="primary"
                class="button px-xl-4 px-sm-1"
                href="/erfa"
              >
                Mehr erfahren
              </b-button>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <div class="py-4 my-lg-3"></div>

    <!-- Partner -->
    <div id="partners">
      <div class="element-container">
        <h2>Unsere Kooperations&shy;partner</h2>
        <div
          class="
            d-flex
            flex-wrap
            justify-content-around
            align-items-center
            element-container
            py-3
          "
        >
          <div v-for="partner in partners" :key="partner.name" class="logo p-2">
            <div v-if="partner.link">
              <a :href="partner.link">
                <b-img :src="partner.logo" :alt="partner.label" fluid></b-img>
              </a>
            </div>
            <div v-else>
              <b-img :src="partner.logo" :alt="partner.label" fluid></b-img>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-lg-4 py-lg-1"></div>

    <!-- Kontakt -->
    <Contact />
  </div>
</template>

<script>
import { getChildsOfSite, getSitePath } from "../config/site-structure";
import { partners } from "../config/partners";
import Contact from "../components/Contact.vue";

export default {
  name: "Home",
  props: {},
  methods: { getChildsOfSite, getSitePath },
  components: { Contact },
  data: () => {
    return {
      partners: partners,
    };
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Apothekenberatung bei der Gründung, Weiterentwicklung und Veräußerung von Apotheken. Profitieren Sie von unserem Wissensvorsprung und unserem Netzwerk.",
      },
      {
        property: "og:image",
        content:
          "https://awh-beratung.de/assets/about/wigand-heppelmann-og.png",
      },
    ],
  },
};
</script>

<style scoped>
#intro-img {
  max-width: 450px;
}
#intro-description {
  max-width: 700px;
}
#partners {
  padding: 20px 0px;
  background-color: #ebebeb;
}
#partners .element-container {
  max-width: 1100px;
  gap: 25px;
}
@media screen and (max-width: 540px) {
  #partners .element-container {
    gap: 5px;
  }
}
.logo {
  width: 200px;
  margin: 10px 10px;
}
@media screen and (max-width: 540px) {
  .logo {
    width: 110px;
    margin: 10px 10px;
  }
}
.reader-area-xl {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.image-header {
  position: relative;
  color: white;
}
.carousel-img {
  width: 100%;
  min-height: 250px;
  object-fit: cover;
  left: 50%;
}
.service-sublist {
  text-align: left;
}
.service-description {
  max-width: 690px;
  margin-left: auto;
  margin-right: auto;
}
</style>