const partners = [
    {
        name: "ami",
        label: "AMI Förder- und Lagertechnik GmbH",
        logo: "/assets/partner/AMI-Logo-sm.png",
        link: "https://www.ami-foerdertechnik.de/"
    },
    {
        name: "eoptimum",
        label: "e.optimum AG",
        logo: "/assets/partner/eoptimum-Logo-sm.png",
        link: "https://www.eoptimum.de/"
    },
    {
        name: "hug",
        label: "H.U.G Betriebswirtschaftliche Beratungsgesellschaft mbH",
        logo: "/assets/partner/HUG-Logo.png",
        link: "https://www.hug-beratung.de/"
    },
    {
        name: "leasfinanz",
        label: "LEASFINANZ GmbH & Co. KG",
        logo: "/assets/partner/LeasFinanz-Logo-sm.png",
        link: "https://leasfinanz.info/"
    },
    {
        name: "noventi",
        label: "NOVENTI Health SE",
        logo: "/assets/partner/NOVENTI-Logo-sm.png",
        link: "https://www.noventi.de/"
    },
    {
        name: "rennet",
        label: "Sabine Rennett Innenarchitektur + Konzept",
        logo: "/assets/partner/rennet-Logo-sm.png",
        link: "https://www.rennett.com/"
    },
    {
        name: "vitasco",
        label: "vitasco GmbH",
        logo: "/assets/partner/vitasco-Logo-sm.png",
        link: "https://vitasco.de/"
    },
];

module.exports.partners = partners;